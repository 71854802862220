<template>
    <div class="home">
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="角色编号" label-width="100px">
                <el-input-number v-model="search.id" :precision="0" :controls='false'></el-input-number>
            </el-form-item>
            <el-form-item label="角色名字" label-width="100px">
                <el-input v-model="search.name"></el-input>
            </el-form-item>
            <el-form-item label="所属渠道" label-width="100px">
                <el-select v-model="search.channelId" style="width: 100%">
                    <el-option v-for="item in channelList" :label='item.name' :value='item.id' :key='item.id'></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合作方式" label-width="100px">
                <el-select v-model="search.ways" style="width: 100%">
                    <el-option label='CPS' :value='1'></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="日期" label-width="100px">
                <el-date-picker v-model="search.fromTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="selectTime" clearable />
            </el-form-item>



            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <div>
            <el-button size='medium' type='primary' @click="editChannel()">创建推广角色</el-button>
        </div>
        <div class="posr">
            <el-table ref="multipleTableRef" v-loading="loadingShow" :data="tableData" style="width: 100%;margin-top: 20px;"
                :header-cell-style="{ background: '#F7F8FA', color: '#333333' }">
                <el-table-column prop="id" label="角色编号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="角色名字" align="center">
                </el-table-column>
                <el-table-column prop="channelname" label="所属渠道" align="center">
                </el-table-column>
                <el-table-column prop="waysstring" label="合作方式" align="center">
                </el-table-column>
                <el-table-column prop="sharelink" label="推广链接" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="copyLink(scope.row.sharelink)" style="white-space: break-spaces;">{{ scope.row.sharelink }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="createtime" label="创建时间" align="center">
                </el-table-column>
                <el-table-column prop="creater" label="创建人" align="center">
                </el-table-column>
                <el-table-column prop="rate" label="合作分成比例" align="center">
                </el-table-column>
                <el-table-column prop="regcount" label="合作新增" align="center">
                </el-table-column>
                <el-table-column prop="recharegenum" label="合作流水" align="center">
                </el-table-column>
                <el-table-column prop="reward" label="合作分成" align="center">
                </el-table-column>
                <el-table-column align="center" fixed="right" width="160px" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editChannel(scope.row)">修改</el-button>
                        <!-- <el-button type="text" @click="deleteFn(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer-pagin">
                <el-pagination :current-page.sync="search.page" :page-sizes="pageSizes" :pager-count="5"
                    :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>

        <el-dialog :title="id?'修改推广角色':'创建推广角色'" :visible.sync="channelVisible" @close="closeDialog" width="40%">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="角色名字" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="所属渠道" prop="channelId">
                        <el-select v-model="ruleForm.channelId" style="width: 100%">
                            <el-option v-for="item in channelList" :label='item.name' :value='item.id' :key='item.id'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合作方式" prop="ways">
                        <el-select v-model="ruleForm.ways" :disabled="id?true:false" style="width: 100%">
                            <el-option label='CPS' :value='1'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分成比例" prop="rate">
                        <el-input-number v-model="ruleForm.rate" :min="0" :max="100" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="注册奖励" prop="invateReward">
                        <el-input-number v-model="ruleForm.invateReward" :min="0" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="推广者手机" prop="mobile">
                        <el-input-number v-model="ruleForm.mobile" :disabled="id?true:false" :precision="0" :controls='false'></el-input-number>
                        手机号码用于生成渠道后台登陆账号
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="channelVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>
  
<script>
// RewardToken
import { AIPromRoleGetPage, AddPromRole, UpdatePromRole, GetChannelList } from '@/service/promotion/promotion.js'
// @ is an alias to /src
import { Message, MessageBox} from 'element-ui'
export default {
    name: 'Home',
    components: {
        
    },
    data() {
        var validateMobile = (rule, value, callback) => {
            let regex = /^1[3456789]\d{9}$/;//使用此正则即可
            if (!regex.test(this.ruleForm.mobile)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
            loadingShow: false,
            channelVisible: false,
            channelList: [
            ],
            ruleForm: {},
            rules: {
                name: [
                    { required: true, message: '请填写角色名字', trigger: 'blur' },
                ],
                channelId: [
                    { required: true, message: '请选择所属渠道', trigger: 'change' },
                ],
                ways: [
                    { required: true, message: '请选择合作方式', trigger: 'change' },
                ],
                rate: [
                    { required: true, message: '请填写分成比例', trigger: 'blur' },
                ],
                invateReward: [
                    { required: true, message: '请填写注册奖励', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请填写推广者手机', trigger: 'blur' },
                    { validator: validateMobile, trigger: "blur" },
                ],
            },
            id: '',
            tableData: [],
            search: {
                page: 1,
                pageSize: 10,
            },
            pageSizes: [10, 20, 50, 100],
            total: 0
        }
    },
    created() {
        this.GetChannelList()
        this.gettableList();
    },
    methods: {  
        copyLink(text){
            var oInput = document.createElement("input");
			oInput.value =  text ;
			document.body.appendChild(oInput);
			oInput.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			oInput.className = "oInput";
			document.body.removeChild(oInput);
			Message.success('复制成功')
        },
        GetChannelList(){
            GetChannelList({}).then(res => {
                this.channelList = res.data
            }).catch(err => {
                console.log(err)
            })
        },
        // 时间选择器
        selectTime(time) {
            console.log(time);
            if (time != null) {
                this.$set(this.search, "timeStart", time[0]);
                this.$set(this.search, "timeEnd", time[1]);
            } else {
                delete this.search.timeStart;
                delete this.search.timeEnd;
            }
        },    
        deleteFn() {
            MessageBox.confirm('请确认是否删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingShow = true
                // DelRole({
                //     id: row.id,
                // }).then(res => {
                //     console.log(res)
                //     this.loadingShow = false
                //     Message.success(`删除成功`)
                //     this.gettableList();
                // }).catch(err => {
                //     this.loadingShow = false
                //     console.log(err)
                // })
            }).catch(() => {
                Message.info('取消删除!')
            })
        },
        editChannel(row) {
            this.id = row ? row.id : ''
            if(this.id){
                let ruleForm = {
                    id: row.id,
                    name: row.name,
                    channelId: row.channelid,
                    ways: row.ways,
                    rate: row.rate,
                    invateReward: row.invatereward,
                    mobile: row.mobile
                }
                this.$set(this,'ruleForm',ruleForm)
            }
            this.channelVisible = true
        },
        
        closeDialog() {
            this.ruleForm = {}
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                if(this.id){
                    UpdatePromRole({ ...this.ruleForm }).then(res => {
                        this.loadingShow = false;
                        console.log(res)
                        this.channelVisible = false
                        Message.success(`修改成功`)
                        this.gettableList()
                    }).catch(err => {
                        console.log(err)
                        this.loadingShow = false;
                    })
                } else {
                    AddPromRole({ ...this.ruleForm }).then(res => {
                        this.loadingShow = false;
                        console.log(res)
                        this.channelVisible = false
                        Message.success(`添加成功`)
                        this.gettableList()
                    }).catch(err => {
                        console.log(err)
                        this.loadingShow = false;
                    })
                }
            });
        },
        serchFn() {
            this.gettableList();
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            this.search.page = 1
            this.search.pageSize = 10
            this.serchFn()
        },
        gettableList() {
            this.loadingShow = true
            const query = {
                ...this.search
            }

            AIPromRoleGetPage(query).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.tableData = res.data.list
                this.total = res.data.count
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        // 每页的条数
        handleSizeChange(val) {
            this.search.pageSize = val;
            this.gettableList();
        },
        // 分页
        handleCurrentChange(val) {
            this.search.page = val;
            this.gettableList();
        },
    }
}
</script>
<style lang="less" scoped>
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.questions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  