import { myPostJson} from '../api.js'
export const GetStatInfos = param => myPostJson(`/AIPromStat/GetStatInfos`, param)

export const AIPromRoleGetPage = param => myPostJson(`/AIPromRole/GetPage`, param)

export const AddPromRole = param => myPostJson(`/AIPromRole/AddPromRole`, param)

export const UpdatePromRole = param => myPostJson(`/AIPromRole/UpdatePromRole`, param)

export const GetManagePage = param => myPostJson(`/AIPromRole/GetManagePage`, param)

export const UpdatePromRoleStatus = param => myPostJson(`/AIPromRole/UpdatePromRoleStatus`, param)

export const GetChannelList = param => myPostJson(`/AIPromChannel/GetChannelList`, param)

export const AddChannel = param => myPostJson(`/AIPromChannel/AddChannel`, param)

export const DelChannel = param => myPostJson(`/AIPromChannel/DelChannel`, param)

